<template>
  <div ref="page">
    <h3 style="text-align:center;margin-top:20px;margin-bottom:20px">
      组织用户
    </h3>
    <main>
      <!-- 新增组织用户的弹框 -->
      <!-- <el-dialog title="创建组织用户" :visible.sync="dialogTableVisible"> -->
      <el-drawer
        title="新增组织用户"
        :visible.sync="dialogTableVisible"
        :direction="direction"
        :size="drawerSize"
        :before-close="resetForm"
      >
        <!-- <div style="display:flex;flex-direction: column;"> -->
        <!-- <div > -->
        <div class="drawer-content">
          <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            label-width="100px"
            class="demo-ruleForm"
            label-position="top"
          >
            <el-form-item
              label="账号前缀"
              prop="loginPrefix"
            >
              <el-input
                v-model="ruleForm.loginPrefix"
                maxlength="10"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="用户名"
              prop="username"
            >
              <el-input
                v-model="ruleForm.username"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="用户组"
              prop="groupId"
            >
              <el-select
                v-model="ruleForm.groupId"
                style="width:100%"
                placeholder="请选择用户组"
              >
                <el-option
                  v-for="(item, index) in groupList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="组织名称"
              prop="name"
            >
              <el-input
                v-model="ruleForm.name"
                maxlength="32"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="所在地区"
              prop="region"
            >
              <el-cascader
                v-model="ruleForm.region"
                style="width: 100%;"
                :options="areasOptions"
                :props="{
                  expandTrigger: 'hover',
                  value: 'name',
                  label: 'name'
                }"
              />
            </el-form-item>
            <el-form-item
              label="联系地址"
              prop="address"
            >
              <el-input
                v-model="ruleForm.address"
                type="textarea"
                maxlength="64"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="联系人"
              prop="contact"
            >
              <el-input
                v-model="ruleForm.contact"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="联系电话"
              prop="mobileNumber"
            >
              <el-input
                v-model="ruleForm.mobileNumber"
                oninput="value=value.replace(/\D|^0/g,'')"
                maxlength="11"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="CRM账号可使用数量上限"
            >
              <el-input
                v-model="ruleForm.staffOnlineMax"
                oninput="value=value.replace(/\D|^0/g,'')"
                maxlength="11"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="CRM外呼坐席可使用数量上限"
            >
              <el-input
                v-model="ruleForm.callSeatMax"
                oninput="value=value.replace(/\D|^0/g,'')"
                maxlength="11"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="备注"
              prop="remarks"
            >
              <el-input
                v-model="ruleForm.remarks"
                type="textarea"
                maxlength="128"
                show-word-limit
                :rows="4"
              />
            </el-form-item>
          </el-form>
          <div class="drawer-footer">
            <el-button
              type="primary"
              :loading="addLoading"
              @click="submitForm('ruleForm')"
            >
              创建
            </el-button>
            <el-button @click="resetForm">
              取消
            </el-button>
          </div>
        </div>
        <!-- </div> -->
        <!-- </div> -->
        <!-- </el-dialog> -->
      </el-drawer>

      <!-- 抽屉 -->
      <!-- <el-drawer
        title="权限管理"
        :visible.sync="showDrawer"
        :direction="direction"
        :before-close="handleClose"
        :size="drawerSize"
      >
        <div class="drawer-content">
          <div class="el-tree-warp">
            <div class="tree-area">
              <el-tree
                ref="tree"
                :data="data"
                show-checkbox
                node-key="permissionId"
                :default-expand-all="true"
                :props="defaultProps"
                @check-change="handleCheckChange"
              ></el-tree>
            </div>
            <div class="checkbox-area">
              <el-checkbox
                style="margin-left:43px"
                v-model="checkedAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
            </div>
          </div>

          <div class="drawer-footer">
            <el-button @click="resetSelect">重 置</el-button>
            <el-button type="primary" @click="saveRole">保 存</el-button>
          </div>
        </div>
      </el-drawer> -->

      <!-- 表格渲染 -->
      <el-card style="height: calc(100vh - 150px);">
        <div style="margin-bottom:10px;">
          <el-button
            type="primary"
            @click="dialogTableVisible = true"
          >
            添加组织用户
          </el-button>
        </div>
        <el-table
          v-tableHeight="{bottomOffset: 90}"
          height="100px"
          :data="tableData"
          :header-cell-style="styleObj"
          border
          style="width: 100%"
          :row-style="{height: '0'}"
          :cell-style="{padding: '0'}"
        >
          <el-table-column
            label="启用"
            width="70"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.enabled"
                :active-value="1"
                :inactive-value="0"
                @change="changeStatus($event, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="username"
            label="用户名"
          />
          <el-table-column
            prop="loginPrefix"
            label="登录账号前缀"
          >
            <template slot-scope="scope">
              <span v-show="!(scope.row.id == idc)">{{
                scope.row.loginPrefix
              }}</span>
              <el-input
                v-show="scope.row.id == idc"
                v-model="scope.row.loginPrefix"
                @focus="focu(scope.row)"
                @blur="hf(scope.row)"
              />
              <i
                class="el-icon-edit"
                @click="saveShow(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="组织名称"
          />
          <el-table-column
            prop="address"
            label="联系地址"
          >
            <template slot-scope="scope">
              {{ scope.row.fullAddress }}
            </template>
          </el-table-column>
          <el-table-column
            prop="contact"
            label="联系人"
          />
          <el-table-column
            prop="mobileNumber"
            label="联系电话"
          />
          <el-table-column
            prop="remarks"
            label="备注"
          />
          <el-table-column
            prop="operating"
            min-width="100"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="medium"
                @click="handleClick(scope.$index, scope.row)"
              >
                编辑
              </el-button>
              <!-- <el-button
                type="text"
                @click="handleRole(scope.row)"
                size="medium"
                >权限管理</el-button
              > -->
              <el-popconfirm
                :title="'确认删除 ' + scope.row.username + '吗？'"
                @confirm="remove(scope.$index, scope.row)"
              >
                <el-button
                  slot="reference"
                  type="text"
                  size="medium"
                  style="color:red;margin-left:10px"
                >
                  删除
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <!-- 组织用户列表分页 -->
        <div
          class="block"
          style="margin-top:20px;text-align:right"
        >
          <el-pagination
            :current-page="page"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="len"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-card>
      <!-- 编辑组织用户的弹框 -->
      <el-drawer
        title="编辑组织用户"
        :visible.sync="dialogTableVisible1"
        size="682px"
      >
        <!-- <div style="display:flex;flex-direction: column;"> -->
        <!-- <div style="height:80%;overflow-y:auto;padding: 0px 20px 20px;"> -->
        <div class="drawer-content">
          <div style="margin-bottom:10px">
            <el-button
              size="mini"
              type="success"
              @click="reset"
            >
              重置密码
            </el-button>
          </div>

          <el-form
            ref="editForm"
            :model="editForm"
            :rules="rules"
            label-width="80px"
            class="demo-ruleForm"
            label-position="top"
          >
            <el-form-item
              label="用户组"
              prop="groupId"
            >
              <el-select
                v-model="editForm.groupId"
                style="width:100%"
                placeholder="请选择用户组"
              >
                <el-option
                  v-for="(item, index) in groupList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="用户名"
              prop="username"
            >
              <el-input
                v-model="editForm.username"
                disabled
              />
            </el-form-item>
            <el-form-item
              label="账号前缀"
              prop="loginPrefix"
            >
              <el-input
                v-model="editForm.loginPrefix"
                maxlength="10"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="组织名称"
              prop="name"
            >
              <el-input
                v-model="editForm.name"
                maxlength="32"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="所在地区"
              prop="region"
            >
              <el-cascader
                v-model="editForm.region"
                style="width: 100%;"
                :options="areasOptions"
                :props="{
                  expandTrigger: 'hover',
                  value: 'name',
                  label: 'name'
                }"
              />
            </el-form-item>
            <el-form-item
              label="联系地址"
              prop="address"
            >
              <el-input
                v-model="editForm.address"
                type="textarea"
                maxlength="64"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="联系人"
              prop="contact"
            >
              <el-input
                v-model="editForm.contact"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="联系电话"
              prop="mobileNumber"
            >
              <el-input
                v-model="editForm.mobileNumber"
                type="text"
                maxlength="11"
                show-word-limit
                oninput="value=value.replace(/\D|^0/g,'')"
              />
            </el-form-item>
            <el-form-item
              label="CRM账号可使用数量上限"
            >
              <el-input
                v-model="editForm.staffOnlineMax"
                oninput="value=value.replace(/\D|^0/g,'')"
                maxlength="11"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="CRM外呼坐席可使用数量上限"
            >
              <el-input
                v-model="editForm.callSeatMax"
                oninput="value=value.replace(/\D|^0/g,'')"
                maxlength="11"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="备注"
              prop="remarks"
            >
              <el-input
                v-model="editForm.remarks"
                type="textarea"
                maxlength="128"
                show-word-limit
              />
            </el-form-item>
            <!-- <el-form-item>
            <el-button type="primary" @click="submitForm1('editForm')"
              >保存</el-button
            >
            <el-button @click="dialogTableVisible1 = false">取消</el-button>
          </el-form-item> -->
          </el-form>
          <!-- </div> -->
          <!-- <div style="text-align:center"> -->
          <div class="drawer-footer">
            <el-button
              type="primary"
              :loading="editLoading"
              @click="submitForm1('editForm')"
            >
              保存
            </el-button>
            <el-button @click="resetEditForm">
              取消
            </el-button>
          </div>
          <!-- </div> -->
        </div>
        <!-- </div> -->
        <!-- </el-dialog> -->
      </el-drawer>
    </main>
  </div>
</template>
<script>
import {
  list,
  add,
  edit,
  remove,
  editloginPrefix,
  editEnable,
  resetMm,
  getRoleList,
  setRole,
} from "../../api/user.js";
// import elementResizeDetectorMaker from "element-resize-detector";
import common from "../until/common";
import { userGroups } from "@/api/public";
export default {
  data() {
    return {
      areasOptions: [],
      styleObj: {
        background: "#f5f7fa",
      },
      addLoading: false,
      editLoading: false,
      tableData: [],
      dialogTableVisible: false,
      groupList: [
        { name: "超级管理员", id: 1 },
        { name: "管理员", id: 2 },
        { name: "销售", id: 3 },
        { name: "普通人", id: 4 },
      ],
      ruleForm: {
        role: "",
        username: "",
        loginPrefix: "",
        name: "",
        groupId: "",
        address: "",
        contact: "",
        region: "",
        mobileNumber: "",
        remarks: "",
        staffOnlineMax: '',
        callSeatMax: ''
      },
      rules: {
        username: [
          { min: 1, max: 40, message: "长度是1-40个字符之间", trigger: "blur" },
          { validator: this.formValidation.username, trigger: "blur" },
        ],
        loginPrefix: [
          { required: true, message: "请输入前缀", trigger: "blur" },
          { min: 1, max: 32, message: "长度是1-32个字符之间", trigger: "blur" },
          { validator: this.formValidation.loginPrefix, trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入组织名称", trigger: "blur" }],
        address: [
          { required: true, message: "请输入联系地址", trigger: "blur" },
        ],
        region: [
          { required: true, message: "请选择所在区域", trigger: "change" },
        ],
        contact: [
          { required: true, message: "请输入联系人名称", trigger: "blur" },
        ],
        mobileNumber: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: this.formValidation.mobile, trigger: "blur" },
        ],
        groupId: [
          { required: true, message: "请选择用户组", trigger: "change" },
        ],
      },
      dialogTableVisible1: false,
      editForm: {
        username: "",
        loginPrefix: "",
        name: "",
        address: "",
        contact: "",
        region: "",
        mobileNumber: "",
        remarks: "",
        groupId: "",
        staffOnlineMax: '',
        callSeatMax: ''
      },
      save: true,
      pre: "",
      pre1: "",
      idc: "",
      page: 1,
      len: 0,
      size: 10,
      // showDrawer: false,
      direction: "rtl",
      selectedArr: [],
      data: [],
      defaultProps: {
        children: "children",
        label: "resourceName",
      },
      checkedAll: false,
      organizationId: "",
      check: [], //菜单权限选中
      drawerSize: "683px",
    };
  },
  mounted() {
    // this.watchSize();
    this.getTableData();
    this.getGroup();
  },
  methods: {
    getTableData() {
      list({ pageNo: this.page, pageSize: this.size }).then((response) => {
        this.tableData = response.data.list;
        this.tableData.forEach((item) => {
          item.fullAddress = item.region.split("/").join("") + item.address;
        });
        this.len = response.data.total;
      });
      this.areasOptions = common.getTreeData(
        JSON.parse(sessionStorage.getItem("areas"))
      );
    },
    getGroup() {
      userGroups().then((res) => {
        if (res.code == 0) {
          this.groupList = res.data.list;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //重置密码
    reset() {
      resetMm({ userOrganizationId: this.editForm.id })
        .then(() => {
          this.$message({
            message: "修改成功，新密码已发送到您的手机中",
            type: "success",
          });
        })
        .catch(() => {
          this.$message.error("修改失败，请刷新后重试");
        });
    },
    //改变当前用户状态
    changeStatus(e, row) {
      editEnable({
        userOrganizationId: row.id,
        enable: row.enabled,
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(row.enabled == 0 ? "禁用成功" : "启用成功");
        } else {
          this.$message.error(res.message);
        }
        this.getTableData();
      });
    },
    //创建组织用户弹框的立即创建按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.region instanceof Array) {
            this.ruleForm.region = this.ruleForm.region.join("/");
          }
          this.addLoading = true;
          add(this.ruleForm).then((res) => {
            if (res.code == 0) {
              list({ pageNo: this.page, pageSize: this.size }).then(
                (response) => {
                  this.tableData = response.data.list;
                  this.len = response.data.total;
                }
              );
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.dialogTableVisible = false;
              this.ruleForm = {
                username: "",
                loginPrefix: "",
                name: "",
                address: "",
                contact: "",
                mobileNumber: "",
                remarks: "",
                staffOnlineMax: '',
                callSeatMax: ''
              };
              this.addLoading = false;
            } else {
              this.$message.error(res.error);
              this.addLoading = false;
            }
          });
        } else {
          this.$message.error("请检查输入框中的内容");
          return false;
        }
      });
    },
    //创建组织用户弹框的取消按钮
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.dialogTableVisible = false;
    },
    resetEditForm() {
      this.$refs.editForm.resetFields();
      this.dialogTableVisible1 = false;
    },
    //编辑按钮
    handleClick(index, row) {
      this.dialogTableVisible1 = true;
      let _row = row;
      console.log(row);
      // 做城市选择拆分
      if (row.region == "//") {
        row.region = "";
      } else {
        try {
          row.region = row.region.split("/");
        } catch (error) {
          console.log(error)
        }
        
      }
      // 浅拷贝对象
      this.editForm = Object.assign({}, _row);
      console.log(this.editForm);
    },
    findAllSelect(arr) {
      arr.forEach((item) => {
        if (item.selected == 1) {
          this.check.push(item.permissionId);
        }
        if (item.children && item.children.length > 0) {
          if (item.selected == 1) {
            this.check.push(item.permissionId);
          }
          this.findAllSelect(item.children);
        }
      });
      return this.check;
    },
    // 设置权限列表
    // handleRole(row) {
    //   // 显示权限抽屉
    //   this.showDrawer = true;
    //   this.organizationId = row.id;
    //   this.getRole(row.id);
    // },
    // getRole(id) {
    //   getRoleList({ organizationId: id }).then((res) => {
    //     if (res.code == 0) {
    //       this.data = res.data.list;
    //       let selected = this.findAllSelect(this.data);
    //       let newArr = selected.reduce((pre, cur) => {
    //         if (!pre.includes(cur)) {
    //           return pre.concat(cur);
    //         } else {
    //           return pre;
    //         }
    //       }, []);
    //       this.$nextTick(() => {
    //         newArr.forEach((item) => {
    //           var node = this.$refs.tree.getNode(item);
    //           console.log(node);
    //           if (node.isLeaf) {
    //             this.$refs.tree.setChecked(node, true);
    //           }
    //         });
    //       });
    //     } else {
    //       this.$message.error(res.message);
    //     }
    //   });
    // },
    //列表编辑中的保存按钮
    submitForm1(editForm) {
      this.$refs[editForm].validate((valid) => {
        this.editLoading = true;
        if (valid) {
          let data = {
            userOrganizationId: this.editForm.id,
            groupId: this.editForm.groupId,
            loginPrefix: this.editForm.loginPrefix,
            name: this.editForm.name,
            region: this.editForm.region.join("/"),
            address: this.editForm.address,
            contact: this.editForm.contact,
            mobileNumber: this.editForm.mobileNumber,
            remarks: this.editForm.remarks,
            staffOnlineMax: this.editForm.staffOnlineMax,
            callSeatMax: this.editForm.callSeatMax
          };
          // this.editForm.userOrganizationId = this.editForm.id;
          // this.editForm.region = this.editForm.region.join("/");
          console.log(data);
          edit(data).then((res) => {
            if (res.code == 0) {
              this.dialogTableVisible1 = false;
              list({ pageNo: this.page, pageSize: this.size }).then(
                (response) => {
                  this.tableData = response.data.list;
                  this.len = response.data.total;
                }
              );
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.editLoading = false;
            } else {
              this.$message.error(res.error);
              this.editLoading = false;
            }
          });
        } else {
          this.$message.error("请检查输入框中的内容");
          return false;
        }
      });
    },
    //列表中的删除按钮
    remove(index, row) {
      console.log(row);
      remove({ userOrganizationId: row.id }).then((res) => {
        if (res.code == 0) {
          list({ pageNo: this.page, pageSize: this.size }).then((response) => {
            this.tableData = response.data.list;
            this.len = response.data.total;
          });
          this.$message({
            message: "删除成功",
            type: "success",
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //在列表中修改员工登陆账号前缀的操作
    saveShow(row) {
      console.log(row);
      if (row.id === this.idc) {
        this.idc = "";
      } else {
        this.idc = row.id;
      }
    },
    //储存一下修改前的值
    focu(row) {
      this.pre1 = row.loginPrefix;
    },
    //失去焦点后将自动保存
    hf(row) {
      this.pre = row.loginPrefix;
      var reg = /^[a-zA-Z]{1}([a-zA-Z0-9]|[_-]){4,9}$/;
      if (reg.test(this.pre)) {
        editloginPrefix({
          userOrganizationId: row.id,
          loginPrefix: this.pre,
        }).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.getTableData();
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
            this.getTableData();
          }
        });
      } else {
        row.loginPrefix = this.pre1;
        this.$message.error("格式错误");
      }
      this.idc = "";
    },
    //字典类型分页的事件
    handleSizeChange(val) {
      this.size = val;
      list({ pageNo: this.page, pageSize: this.size }).then((response) => {
        this.tableData = response.data.list;
      });
    },
    // 分页操作
    handleCurrentChange(val) {
      this.page = val;
      list({ pageNo: this.page, pageSize: this.size }).then((response) => {
        this.tableData = response.data.list;
      });
    },
    // 设置选权限中以及半选的值
    handleCheckChange() {
      let selected = this.$refs.tree
        .getCheckedNodes()
        .concat(this.$refs.tree.getHalfCheckedNodes());
      this.selectedArr = selected;
      let count = 0;
      this.data.forEach((item) => {
        count = count + 1;
        if (item.children && item.children.length > 0) {
          item.children.forEach((v) => {
            count = count + 1;
            if (v.children) {
              count = count + v.children.length;
            }
          });
        }
      });
      console.log(count);
      if (selected.length != count) {
        this.checkedAll = false;
      } else {
        this.checkedAll = true;
      }
    },
    // 重置选择的权限
    resetSelect() {
      this.$refs.tree.setCheckedKeys([]);
    },
    // 保存权限信息
    saveRole() {
      /**
       这一段注释代码是传值的时候传回给后端的值的处理
       let data = this.selectedArr.map(item => item.id)
      console.log(data);
      [1, 4, 6, 2]
      console.log("我是保存");

       */

      // 这一段是回显半选的问题解决方案
      // let arr = [1, 4, 5, 2];
      // arr.forEach((item) => {
      //   var node = this.$refs.tree.getNode(item);
      //   console.log(node);
      //   if (node.isLeaf) {
      //     this.$refs.tree.setChecked(node, true);
      //   }
      // });

      let arr = this.selectedArr.map((item) => item.permissionId);
      console.log(arr);
      setRole({
        organizationId: this.organizationId,
        permissionIds: arr,
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message.success("已成功设置权限");
          this.handleClose();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleCheckAllChange(val) {
      if (val) {
        console.log(this.data);
        this.$refs.tree.setCheckedNodes(this.data);
        console.log(this.$refs.tree.setCheckedNodes(this.data));
      } else {
        this.resetSelect();
      }
    },
    // 关闭权限的抽屉弹框
    handleClose() {
      this.showDrawer = false;
      this.check = [];
      this.resetSelect();
    },
  },
};
</script>
<style lang="less" scoped>
main {
  padding: 0 24px;
}
::v-deep .el-dialog {
  width: 25%;
  min-width: 400px;
}
::v-deep .el-dialog__header {
  text-align: center;
}
::v-deep .el-table__row td {
  position: relative;
  i {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
  }
}
/deep/ .el-drawer:focus {
  outline: none;
}
/deep/ .el-drawer__header {
  margin: 0;
  padding: 0;
  margin: 20px 20px 0px 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  // padding: 0;
  span {
    outline: none;
    font-weight: 600;
    color: #000;
  }
}
.role-list {
  border: 1px solid #ccc;
  margin: 20px 43px;
  border-radius: 8px;
  padding: 20px;
  min-height: 500px;
}
/deep/ .el-form-item {
  margin-bottom: 18px;
}
/deep/ .el-form-item__label {
  line-height: 0;
}
.drawer-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  .el-form,
  .el-tree-warp {
    flex: 1;
    height: 0;
    overflow-y: auto;
  }
  .tree-area {
    border: 1px solid #ccc;
    margin: 20px 43px;
    border-radius: 8px;
    padding: 20px;
    min-height: 500px;
  }
  .drawer-footer {
    display: flex;
    justify-content: center;
    // .el-button {
    //   // flex: 1;
    // }
  }
}
/deep/ .el-drawer__body {
  overflow-y: scroll;
}
</style>
